import React from 'react'
import {Link} from 'gatsby'

const ServicesOne = () => {
    return (
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>
                            <h3>
                                <Link to="/service-details">
                                Remote Support
                                </Link>
                            </h3>
                            <p>UK Nationwide Remote Mac IT Support for Home Users and Companies.</p>

                            <Link className="view-details-btn" to="/service-details">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>
   
                            <h3>
                                <Link to="/service-details">
                                Apple Accreditation
                                </Link>
                            </h3>

                            <p>Professional Mac Support Expertise and Apple Certified.</p>
                            
                            <Link className="view-details-btn" to="/service-details">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-money"></i>
                            </div>

                            <h3>
                                <Link to="/service-details">
                                Upfront cost - No hidden charges
                                </Link>
                            </h3>

                            <p>Remote Support £32 per incident, advice is Free.</p>
                            
                            <Link className="view-details-btn" to="/service-details">
                                View Details
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesOne